/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';

// Core
import { Router } from '@app';
import { Button } from '@client/components';

// App
import useHeader from '@client/pages/useHeader';
import Page from '../platform/_page';

/*----------------------------------
- CONTROLEUR
----------------------------------*/
Router.error( 404, { layout: 'platform' }, ({ data }) => {
    return (
        <Page title="Page Not Found" description={data.message}>
            <div class="col al-center">

                <div class="card col al-center w-a-4">

                    <i src="times-circle" class="xxl fg error" />

                    <h1>Page Not Found</h1>

                    <p>{data.message || 'The page you asked for was not found.'}</p>

                    <Button type="primary" link="/">Go back to home</Button>
                </div>
            </div>
        </Page>
    )
});